import React from 'react';

import Layout from '../components/layout/Layout';


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/social-responsability`,

  },


];

const seo={
  title: "Nuestra política ambiental",
  desc:  "Descubre nuestra politica de medio ambiente",
  image: "",
  pathname: "/politica-mideo-ambiente/",
}

 const MedioAmbiente = ({location}) =>  (

  <Layout location={location} crumbLabel="Politica de medio ambiente"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="" className="p-10">


    <div className="container font-serif">
        <h1 className="text-lg font-bold">Nuestras Políticas del Medio Ambiente</h1>
    <p>•	Unilimpio está comprometido con la conservación y respeto al medio ambiente<br/>
•	Unilimpio adquiere materias primas biodegradables para lograr mantener esta característica durante toda la cadena.<br/>
•	Unilimpio trabaja con proveedores certificados con FSC en productos definidos. <br/>
•	Unilimpio trabaja en el cumplimiento de todos los requisitos normativos en materia ambiental.<br/>
•	Unilimpio identifica y valora los impactos ambientales generados por la actividad productiva y comunica a las partes interesadas.<br/>
•	Unilimpio capacita a todo el personal sobre el uso eficiente de recursos, la importancia del ahorro, con énfasis en los No Renovables.<br/>
•	Unilimpio desarrolla productos cuidando el medio ambiente. <br/>
•	Unilimpio trabaja con el reúso de los residuos susceptibles de aprovechamiento interno.<br/>
•	Unilimpio disminuye los residuos de agua generados en los diferentes procesos.<br/>
•	Unilimpio mide y controla el uso responsable de la energía eléctrica.<br/>
•	Unilimpio gestiona los desechos y residuos peligrosos y no peligros con gestores autorizados.<br/>
•	Unilimpio trabaja con proveedores que cumplen con las normativas ambientales.<br/>
•	Unilimpio trabaja con la comunidad en actividades para reducir el consumo de plástico y en campañas enfocadas en el medio ambiente.<br/>
</p>



    </div>



</section>

  

  </Layout>
);

export default MedioAmbiente;
